/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'loading': {
    width: 56,
    height: 56,
    viewBox: '0 0 56 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M27.146 52.974l.036.002c.27.016.54.024.818.024 13.807 0 25-11.193 25-25 0-.278-.008-.548-.024-.818l-.002-.035-.001-.036c-.48-13.566-11.7-24.27-25.273-24.11C14.126 3.162 3.162 14.127 3.002 27.7c-.16 13.574 10.543 24.793 24.11 25.273l.035.001zm28.825-25.969C55.434 11.811 42.868-.177 27.665.002 12.462.182.18 12.462.002 27.665c-.18 15.203 11.81 27.769 27.003 28.306.329.02.657.03.995.03 15.464 0 28-12.537 28-28 0-.339-.01-.667-.029-.996z" _fill="#fff"/><path pid="1" d="M27.21 10.303a1.73 1.73 0 00.633 2.364l13.49 7.789a1.73 1.73 0 101.731-2.998L29.574 9.67a1.73 1.73 0 00-2.364.634zM14.86 35.15a1.73 1.73 0 00-1.73 3l13.49 7.788a1.731 1.731 0 001.73-2.998l-13.49-7.79z" _fill="#fff"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M26.113 18.664a6.277 6.277 0 00.164 6.548 1.5 1.5 0 01-1.335 2.312 6.279 6.279 0 00-5.753 3.131l-2.279 3.948 10.893 6.289 2.279-3.948a6.278 6.278 0 00-.164-6.547 1.5 1.5 0 011.335-2.312 6.278 6.278 0 005.752-3.132l2.28-3.947-10.893-6.29-2.28 3.948zm1.73-5.997l13.49 7.789-3.029 5.247a7.779 7.779 0 01-7.127 3.88 7.78 7.78 0 01.203 8.112l-3.028 5.246-13.49-7.79 3.028-5.245a7.779 7.779 0 017.127-3.88 7.778 7.778 0 01-.203-8.112l3.03-5.247z" _fill="#fff"/>'
  }
})
